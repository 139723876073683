import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import LandingPage from './pages';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({duration: 1000});
function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route key="landing" path='*' element={<LandingPage />} />,
          {/* <Route key="auth" path=''>
            <Route key="login" path='/login' element={<Login />} />,
          </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
