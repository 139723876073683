import React from 'react'

const Facebook = () => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3565_13332)">
				<path d="M24.3633 12.5024C24.3633 6.08057 19.1602 0.877441 12.7383 0.877441C6.31641 0.877441 1.11328 6.08057 1.11328 12.5024C1.11328 18.3046 5.36438 23.114 10.9219 23.9868V15.8629H7.96875V12.5024H10.9219V9.94119C10.9219 7.02791 12.6563 5.41869 15.3127 5.41869C16.5848 5.41869 17.9152 5.64557 17.9152 5.64557V8.50494H16.4489C15.0052 8.50494 14.5547 9.40119 14.5547 10.3204V12.5024H17.7787L17.2631 15.8629H14.5547V23.9868C20.1122 23.114 24.3633 18.3046 24.3633 12.5024Z" fill="#03E5AE" />
			</g>
			<defs>
				<clipPath id="clip0_3565_13332">
					<rect width="24" height="24" fill="white" transform="translate(0.738281 0.502441)" />
				</clipPath>
			</defs>
		</svg>

	)
}

export default Facebook