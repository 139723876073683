import React from 'react'

const Insta = () => {
	return (
		<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.36632 6.37824C7.64601 6.37824 6.2421 7.78214 6.2421 9.50245C6.2421 11.2228 7.64601 12.6267 9.36632 12.6267C11.0866 12.6267 12.4905 11.2228 12.4905 9.50245C12.4905 7.78214 11.0866 6.37824 9.36632 6.37824ZM18.7366 9.50245C18.7366 8.2087 18.7484 6.92667 18.6757 5.63527C18.603 4.13527 18.2609 2.80402 17.164 1.70714C16.0648 0.607924 14.7359 0.26808 13.2359 0.195424C11.9421 0.122767 10.6601 0.134486 9.36866 0.134486C8.07491 0.134486 6.79288 0.122767 5.50148 0.195424C4.00148 0.26808 2.67023 0.610267 1.57335 1.70714C0.474135 2.80636 0.134291 4.13527 0.0616346 5.63527C-0.0110216 6.92902 0.000697135 8.21105 0.000697135 9.50245C0.000697135 10.7939 -0.0110216 12.0782 0.0616346 13.3696C0.134291 14.8696 0.476478 16.2009 1.57335 17.2978C2.67257 18.397 4.00148 18.7368 5.50148 18.8095C6.79523 18.8821 8.07726 18.8704 9.36866 18.8704C10.6624 18.8704 11.9444 18.8821 13.2359 18.8095C14.7359 18.7368 16.0671 18.3946 17.164 17.2978C18.2632 16.1985 18.603 14.8696 18.6757 13.3696C18.7507 12.0782 18.7366 10.7962 18.7366 9.50245ZM9.36632 14.3095C6.70617 14.3095 4.55929 12.1626 4.55929 9.50245C4.55929 6.8423 6.70617 4.69542 9.36632 4.69542C12.0265 4.69542 14.1734 6.8423 14.1734 9.50245C14.1734 12.1626 12.0265 14.3095 9.36632 14.3095ZM14.3702 5.6212C13.7491 5.6212 13.2476 5.11964 13.2476 4.49855C13.2476 3.87745 13.7491 3.37589 14.3702 3.37589C14.9913 3.37589 15.4929 3.87745 15.4929 4.49855C15.4931 4.64603 15.4642 4.7921 15.4078 4.92839C15.3515 5.06468 15.2688 5.18851 15.1645 5.2928C15.0602 5.39708 14.9364 5.47977 14.8001 5.53613C14.6638 5.59248 14.5177 5.62139 14.3702 5.6212Z" fill="#03E5AE" />
		</svg>

	)
}

export default Insta