import React, {useState} from 'react'
import DropDownArr from '../assets/jsx/DropDownArr'
import utils from '../services/untils';

const FAQs = () => {
	const [ci, setci] = useState(null);
	const [ci2, setci2] = useState(null);

	return (
		<div className='hsection pt-10 md:pt-24'>
			<h3 data-aos="fade-down" className='text-2xl md:text-5xl text-center tracking-[0.4rem] max-w-2xl mx-auto font-libre font-bold'>All your Questions <span className='text-light-blue'>answered</span></h3>
			<p data-aos="fade-up" className='tracking-[0.2rem] text-lg text-center py-4'>most questions asked by our customers</p>
			<div className="grid md:grid-cols-2 gap-3 pt-2">
				<div data-aos="fade-up" className='flex flex-col gap-3'>
					{utils.questions.map((each, i) => (
						<div onClick={() => {(ci === i) ? setci(null) : setci(i)}} className={`${(ci === i) ? 'bg-light-blue text-white stroke-white' : 'text-[#1A1111] bg-neutral-background stroke-[#1A1111]'} transition-all duratino-400 rounded-[0.6rem] px-4 py-6 cursor-pointer`} key={each.question}>
							<h3 className='flex gap-3 justify-between text-lg md:text-xl '>
								<span>{each.question}</span>
								<DropDownArr className={`${ci === i ? '' : '-scale-y-100'} w-5 min-w-[1.25rem]`} />
							</h3>
							<p className={`${ci === i ? '' : 'hidden'} text-base pt-3 pr-5`}>
								{each.answer}
							</p>
						</div>
					))}
				</div>
				<div data-aos="fade-up" className='flex flex-col gap-3'>
					{utils.question2.map((each, i) => (
						<div onClick={() => {(ci2 === i) ? setci2(null) : setci2(i)}} className={`${(ci2 === i) ? 'bg-light-blue text-white stroke-white' : 'text-[#1A1111] bg-neutral-background stroke-[#1A1111]'} transition-all duration-500 rounded-[0.6rem] px-4 py-6 cursor-pointer`} key={each.question}>
							<h3 className='flex gap-3 justify-between text-lg md:text-xl '>
								<span>{each.question}</span>
								<DropDownArr className={`${ci2 === i ? '' : '-scale-y-100'} w-5 min-w-[1.25rem]`} />
							</h3>
							<p className={`${ci2 === i ? '' : 'hidden'} text-base pt-3 pr-5`}>
								{each.answer}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default FAQs