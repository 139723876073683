import React from 'react'
import googlePlay from '../assets/google-play.png'
import appStore from '../assets/app-store.png';
import {PropTypes} from 'prop-types';


const AppDownLoad = ({className}) => {
	return (
		<div className={`flex flex-wrap h-fit gap-5 py-5 ` + className}>
			<a href='https://getwaitlist.com/waitlist/11186' target='_blank' rel='noreferrer'>
				<img className='w-32 md:w-40 rm-drag' src={googlePlay} alt='get-on-google-play' />
			</a>
			<a href='https://getwaitlist.com/waitlist/11186' target='_blank' rel='noreferrer'>
				<img className='w-32 md:w-40 rm-drag' src={appStore} alt='get-on-app-store' />
			</a>
		</div>
	)
}

AppDownLoad.propTypes = {
	className: PropTypes.string
}

export default AppDownLoad