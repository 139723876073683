import React from 'react'
import Star from '../assets/jsx/Star'
import {PropTypes} from 'prop-types';


const Testimonial = () => {
	return (
		<div className='bg-[#E7F1FE] pt-5 pb-10 md:pb-20'>
			<div className="hsection">
				<h3 data-aos="fade-down" className='text-center max-w-2xl mx-auto font-libre text-2xl md:text-5xl font-bold tracking-[0.4rem]'>What our <span className='text-secondary'>Customers</span> say about <span className='text-light-blue uppercase'>us</span>
				</h3>
				<p data-aos="fade-up" className='tracking-[0.2rem] text-lg py-4 text-center font-normal'>All the good stuff about  what we do for You </p>
				<div className="grid grid-cols-7 gap-4">
					<Testi img="https://images.unsplash.com/photo-1587064712555-6e206484699b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsYWNrJTIwbWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" className="bg-[#8AA6FE] md:col-span-2 mx-10 md:mx-0" text={'The instant alert is really nice. It takes less than 1 minute to receive an alert. And the app is so easy to use. Nice product AjoBank.'} personName={'Kunle Remi'} />
					<Testi img="https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxhY2slMjBtYW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60" className="bg-[#F5F5F5] hidden md:flex md:col-span-2" text={'Thank you so much for this solution. If this had existed earlier, my business would not have crashed in 2021. But now i can start again without fear. Thank you AjoBank team.'} personName={'Akin Onifade '} />
					<Testi img="https://images.unsplash.com/photo-1563132337-f159f484226c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxhY2slMjBsYWR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" className="bg-[#9DFEE6] md:col-span-3" text={'With AjoBank, no one needs another bank. I opened account in less than 3 minutes, i can save, access loans , invest, and even buy health insurance for myself and my parents. I have a card now and USSD works. This is just perfect. I even make money every month from the app by opening account for other people. Thank you AjoBank.'} personName={'Sade Adetutu'} />
					<Testi img="https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxhY2slMjBtYW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60" className="bg-[#F5F5F5] md:hidden mx-10" text={'Thank you so much for this solution. If this had existed earlier, my business would not have crashed in 2021. But now i can start again without fear. Thank you AjoBank team.'} personName={'Akin Onifade '} />
					<Testi img="https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGJsYWNrJTIwbWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" className="bg-[#FFD986] md:col-span-4" text={'This is a real hands-on solution that can extend financial services to people in multiple communities at the same time and reach very far, farther than most of these other fintech solutions today can possibly reach'} personName={'Yinka A.'} />
					<Testi img="https://images.unsplash.com/photo-1561406636-b80293969660?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YmxhY2slMjBsYWR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60" className="bg-[#FFC2D8] md:col-span-3" text={'Everyone in my thrift group now uses AjoBank for all their ajo. I recommended it for them. So now nobody is afraid that they will carry their money away since everybody\'s money is in their own AjoBank wallet account and not with the collector.'} personName={'Mama Modinot'} />
				</div>
			</div>
		</div>
	)
}

const Testi = ({text, stars = 5, personName, className, img}) => {
	return (
		<div data-aos="zoom-in" className={`border border-white p-5 rounded-[1.25rem] flex tracking-[0.14rem] text-sm flex-col col-span-12 ${className}`}>
			<p className='my-auto'>{text}</p>
			<div className='flex gap-2 items-center pt-3 mt-auto'>
				<img className='rounded-full h-9 w-9 object-cover rm-drag' src={img} alt='person' />
				<div className='flex flex-col'>
					<span>{personName}</span>
					<div className='flex'>
						{new Array(stars).fill(0).map((each, i) => (
							<Star key={i} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

Testi.propTypes = {
	className: PropTypes.string.isRequired,
	personName: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	stars: PropTypes.number,
}


export default Testimonial