import {useEffect, useState} from "react";
import MenuIcon from "../assets/jsx/MenuIcon";
import logo from '../assets/logo.png'
import {Link} from "react-router-dom";
import './index.css';

const Navbar = () => {
	const [navIsOpen, setNavIsOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false)
	const listenScrollEvent = () => {
		if (window.scrollY > 50) {
			setScrolled(true)
		} else {
			setScrolled(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent)
		return () => {
			window.removeEventListener('scroll', listenScrollEvent)
		}
	}, [])

	return (
		<div className={`${navIsOpen ? 'h-[26rem] pt-5 md:pt-3 md:h-[6rem] md:shadow-none shadow-lg overflow-visible' : 'pt-5 shadow md:shadow-none md:pt-3 h-[6rem] overflow-hidden md:overflow-visible'} ${scrolled ? 'shadow-md' : ''} z-[110] pb-2 transition-all duration-500  w-full top-0 fixed inset-x-0 bg-white fade-down`}>
			<div className={`flex flex-col md:flex-row hsection gap-1 text-pry justify-between md:items-center mx-auto pb-3 pt-3`}>
				<div className="flex flex-col md:flex-row md:items-center md:gap-3">
					<div className="w-full md:w-fit gap-3 lg:gap-5 flex justify-between md:items-center">
						<h3 className=' flex gap-1 items-center'>
							<img className="rm-drag w-[5.3rem]" src={logo} alt="logo" />
						</h3>
						<div className='text-right col-span-2 md:hidden'>
							<button onClick={() => setNavIsOpen(e => !e)}>
								<MenuIcon />
							</button>
						</div>
					</div>
					<ul className="pt-4 md:pt-0 flex flex-col md:flex-row lg:gap-x-5">
						{
							[
								{text: 'Why Gate?'},
								{
									text: 'Product', sub: [
										{title: 'Money Transfer', subtitle: 'Access affordable protection for your properties and yourself'},
										{title: 'Credit', subtitle: 'Access credit facility for you and your community'},
										{title: 'Investment', subtitle: 'Put your funds to good use through guaranteed plans'},
										{title: 'Insurance', subtitle: 'Access affordable protection for your properties and yourself'},
									]
								},
								{
									text: 'Company', sub: [
										{title: 'About Us', subtitle: 'Access affordable protection for your properties and yourself'},
										{title: 'Careers', subtitle: 'Find opportunities at Gate and join the team'},
										{title: 'Terms and Privacy Policy', subtitle: 'All activities on Gate are governed by these policies'},
										{title: 'Compliance and security', subtitle: 'All activities on Gate are governed by these policies on security and compliance'},
									]
								},
							].map(each => (
								<li key={each.text} className='group relative rounded w-full md:text-center md:w-fit md:bg-transparent p-2 font-bold transition cursor-pointer '>
									{each.text}
									{
										each.sub && <div className="opacity-0 group-hover:opacity-100 flex flex-col transition-all duration-500 z-20 absolute top-10 shadow-lg w-[21.25rem] rounded-t-xl text-left bg-white overflow-hidden pointer-events-none group-hover:pointer-events-auto">
											{each.sub.map((each, i) => (
												<div key={i} className="p-3 hover:bg-form transition duration-300">
													<h3 className="text-base">{each.title}</h3>
													<p className="text-sm font-normal text-neutral-1">{each.subtitle}</p>
												</div>
											))}
										</div>
									}
								</li>
							))
						}
					</ul>
				</div>
				<div className="flex flex-col md:gap-4 md:flex-row md:items-center">
					<ul className='flex flex-col md:flex-row lg:gap-x-5'>

						{
							[
								{text: 'Blog'},
								{text: 'Contact Us'},
								{text: 'Login', link: 'https://app.getgate.africa/'},
							].map(each => (
								<li key={each.text} className='rounded w-full md:text-center md:w-fit md:bg-transparent p-2 font-bold transition cursor-pointer '>
									<Link to={each.link ?? '#'}>{each.text}</Link>
								</li>
							))
						}
					</ul>
					<Link to="https://app.getgate.africa/" className='bg-light-blue text-white mt-2 md:mt-0 rounded-md py-3 px-5 font-bold w-fit whitespace-nowrap'>
						<span className="md:hidden lg:inline">Create Account</span>
						<span className="hidden md:inline lg:hidden">SignUp</span>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Navbar
