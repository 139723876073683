const utils = {
	questions: [
		{
			question: "How to get started", answer: "Sign up on the website or download the app from Google play store or IOS and fill all the required information. Complete verification process and you will get an account number instantly."
		},
		{
			question: "How to reach out to support?", answer: "You can reach out to our customer care support team using the chatbot on the website or on the app, via email at support@getgate.africa."
		},
		{
			question: "How to access loans and does USSD work for it? ", answer: "Apply directly on the app. Once eligible and approved, account will be credited instantly. "
		},
		{
			question: "Does USSD work for Loans? ", answer: " Yes you can request for loans using the USSD code. Please dial ** to get started."
		},

		{
			question: "How can I get my card and is it free?", answer: "You can create a virtual card on the app which can be used to purchase items online. If you want a physical card, please request a physical card on the app and it will be delivered in less than 48 hours."
		},
	],
	question2: [
		{
			question: "Is the Card free?", answer: "Virtual card is free. The physical card is only FREE to our first 10,000 customers. Hurry to request now and get one since its still free. "
		},
		{
			question: "Is it free to start", answer: "Yes, it is totally free ,just download the app and sign up to get started "
		},
		{
			question: "How you can partner with us?", answer: "You can reach out to the business team via email on partner@getgate.africa or use the chatbot."
		},
		{
			question: "Is AjoBank licensed? ", answer: "Gate (formerly AjoBank) is partnered with WEMA bank which is licensed by CBN (Central Bank of Nigeria) and insured by NDIC (Nigeria Deposit Insurance Corporation )."
		},
		{
			question: "How can I get POS for my business?", answer: "You can request for a POS device via email at POS@getgate.africa. Our sales team will reach out to you and provide you with all necessary items in less than 48 hours"
		},
	]
}

export default utils;