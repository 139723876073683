import React from 'react'

const Twitter = () => {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.3713 8.49949C20.3843 8.67449 20.3843 8.84849 20.3843 9.02249C20.3843 14.3475 16.3313 20.4835 8.92428 20.4835C6.64228 20.4835 4.52228 19.8225 2.73828 18.6745C3.06228 18.7115 3.37428 18.7245 3.71128 18.7245C5.52427 18.7289 7.28593 18.1226 8.71228 17.0035C7.8717 16.9883 7.05686 16.7109 6.38152 16.2102C5.70618 15.7095 5.20407 15.0104 4.94528 14.2105C5.19428 14.2475 5.44428 14.2725 5.70628 14.2725C6.06728 14.2725 6.43028 14.2225 6.76728 14.1355C5.85504 13.9513 5.03475 13.4568 4.4459 12.7362C3.85704 12.0155 3.53597 11.1131 3.53728 10.1825V10.1325C4.07428 10.4315 4.69728 10.6185 5.35728 10.6435C4.80437 10.2761 4.351 9.77751 4.03762 9.19228C3.72424 8.60704 3.56059 7.95334 3.56128 7.28948C3.56128 6.54148 3.76028 5.85548 4.10928 5.25748C5.12142 6.5025 6.38378 7.52105 7.81462 8.24714C9.24545 8.97324 10.8128 9.3907 12.4153 9.47249C12.3533 9.17248 12.3153 8.86148 12.3153 8.54948C12.315 8.02045 12.419 7.49654 12.6214 7.00772C12.8237 6.51891 13.1204 6.07476 13.4945 5.70067C13.8686 5.32659 14.3127 5.0299 14.8015 4.82756C15.2903 4.62523 15.8142 4.52122 16.3433 4.52148C17.5033 4.52148 18.5503 5.00748 19.2863 5.79348C20.1881 5.6191 21.0528 5.28991 21.8423 4.82048C21.5417 5.7513 20.912 6.54059 20.0713 7.04048C20.8711 6.94926 21.6527 6.73894 22.3903 6.41648C21.8394 7.21958 21.1568 7.92383 20.3713 8.49949Z" fill="#03E5AE" />
		</svg>

	)
}

export default Twitter