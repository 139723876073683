import curacel from '../assets/curacel.png'
import sentra from '../assets/sentra.png'
import reeple from '../assets/reeple.png'
import wemaBank from '../assets/wema-bank.png'
import ecotutu from '../assets/ecotutu.png'
import veend from '../assets/veend.png'
import GTBank from '../assets/jsx/GTBank'

const Partners = () => {
	const parts = [
		<img key="curacel" src={curacel} alt="curacel" className='h-16 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all -ml-[.6rem] md:ml-0 -mt-1' />,
		<img key="sentra" src={sentra} alt="sentra" className='h-5 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all -ml-3 md:ml-0' />,
		<img key="reeple" src={reeple} alt="reeple" className='h-5 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all ' />,
		<img key="wemaBank" src={wemaBank} alt="wemaBank" className='h-20 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all mt-1 -ml-2 md:ml-0 ' />,
		<img key="ecotutu" src={ecotutu} alt="ecotutu" className='h-6 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all ' />,
		<img key="veend" src={veend} alt="veend" className='h-5 rm-drag grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all -mt-2' />,
		<GTBank key="gtbank" className="h-10 grayscale opacity-40 hover:grayscale-0 hover:opacity-100 duration-300 transition-all" />
	]
	return (
		<div className='bg-neutral-background px-2' data-aos="fade-up">
			<div className='hsection pt-2 my-5'>
				<h6 className="font-light md:text-center text-sm py-2">OUR PARTNERS</h6>
				<div className='flex flex-wrap gap-x-4 justify-start md:justify-between items-center'>
					{parts.map(each => (each))}
				</div>
			</div>
		</div>
	)
}

export default Partners