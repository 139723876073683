import React from 'react'

const Polygon = () => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.91421 6L6 1.91421L10.0858 6L6 10.0858L1.91421 6Z" stroke="white" strokeWidth="2" />
		</svg>

	)
}

export default Polygon