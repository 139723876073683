import React from 'react'
import StarBg from '../assets/jsx/StarBg'
import Polygon from '../assets/jsx/Polygon';
import Cylinders from '../assets/jsx/Cylinders';
import AppDownLoad from './AppDownLoad';

const Cardbox = () => {
	return (
		<div className='bg-[#E7F1FE] py-20 md:py-28'>
			<div className="hsection">
				<div data-aos="fade-down" className='relative overflow-hidden rounded-[1.25rem] text-white bg-light-blue pt-20 md:pt-32 md:pb-24 px-5'>
					<StarBg className="absolute inset-0 h-full" />
					<div className='max-w-lg relative z-10'>
						<h2 className='font-bold block font-libre text-2xl md:text-5xl tracking-[0.09rem]'>Spend with <span className='text-tertiary'>one</span> Card, virtually and physically</h2>
						<p className='text-lg pt-5 max-w-sm font-normal'>The Gate card is a companion for all your physical and virtual shopping activities</p>
						<AppDownLoad className='pb-0 md:pb-5' />
					</div>
					<div className='bottom-0 max-w-md md:absolute md:right-2 md:bottom-0 w-full px-2'>
						<Cylinders className="w-full" />
					</div>
					<div className='absolute bottom-0 inset-x-0 bg-pry py-4 flex gap-2 text-pry whitespace-nowrap overflow-hidden'>
						{new Array(10).fill(0).map((each, i) => (
							<div key={i} className='flex gap-2 items-center'>
								<span className='[-webkit-text-stroke:_.5px_#fff]'>INSTANT PAYMENT </span>
								<Polygon />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cardbox