import React from 'react'
import pos from '../assets/pos.png';
import youngMan from '../assets/young-man.png';
import lady from '../assets/lady.png';
import CircularLogo from '../assets/jsx/CircularLogo';

const Services = () => {
	return (
		<div className='hsection grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-2 md:gap-5 pt-28 md:pt-36 pb-0'>
			<div className='col-span-1 sm:hidden'>
				<ServicesHero />
			</div>
			<div data-aos="fade" className='relative col-span-1 rounded-[1.25rem] group'>
				<CircularLogo className='absolute z-10 right-5 sm:-right-[3.5rem] -bottom-[3.5rem] w-24' />
				<img src={pos} alt='pos' className='rm-drag w-full h-full object-cover' />
				<div className='bg-[#212121]/30 h-14 blur-sm opacity-0 group-hover:opacity-100 transition-all rounded-b-[1.25rem] duration-500 text-white absolute w-full bottom-0'>
				</div>
				<h3 className='text-sm sm:text-lg text-white absolute w-full bottom-0 py-3 px-5 font-bold opacity-0 group-hover:opacity-100 transition-all duration-500'>Point -Of-Sale Services</h3>
			</div>
			<div className='col-span-1 hidden sm:block'>
				<ServicesHero />
			</div>
			<div data-aos="fade" className='relative col-span-1 overflow-hidden rounded-[1.25rem] group'>
				<img src={youngMan} alt='pos' className='rm drag w-full' />
				<div className='bg-[#212121]/30 h-14 blur-sm opacity-0 group-hover:opacity-100 transition-all duration-500 text-white absolute w-full bottom-0'>
				</div>
				<h3 className='text-sm sm:text-lg text-white absolute w-full bottom-0 py-3 px-5 font-bold opacity-0 group-hover:opacity-100 transition-all duration-500'>Instant Payment through USSD</h3>
			</div>
			<div data-aos="fade" className='relative col-span-1 overflow-hidden rounded-[1.25rem] group'>
				<img src={lady} alt='pos' className='rm drag w-full' />
				<div className='bg-[#212121]/30 h-14 blur-sm opacity-0 group-hover:opacity-100 transition-all duration-500 text-white absolute w-full bottom-0'>
				</div>
				<h3 className='text-sm sm:text-lg text-white absolute w-full bottom-0 py-3 px-5 font-bold opacity-0 group-hover:opacity-100 transition-all duration-500'>Card Services</h3>
			</div>
		</div>
	)
}

const ServicesHero = () => {
	return (
		<div data-aos="fade-up" data-aos-duration="1000" className='flex justify-center flex-col pl-0 sm:pl-2 p-2'>
			<h3 className='text-2xl md:text-3xl lg:text-5xl font-libre font-bold tracking-[0.4rem]'>
				Other financial services for <span className='text-light-blue'>you</span> and <span className='text-tertiary'>your community</span>
			</h3>
			<p className='md:text-xl tracking-[0.2rem] my-3'>All opportunities enabled!</p>
		</div>
	)
}

export default Services