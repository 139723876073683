import React from 'react'
import CircularLogo from '../assets/jsx/CircularLogo';
import {useEffect, useState} from 'react';
import AppDownLoad from './AppDownLoad';

const DashTop = () => {

	const texts = [
		<div key="first" className='bg-pry rounded-full py-2 w-fit font-light px-4 text-xs sm:text-sm text-[#E7F1FE] transition-[width] duration-500 fade'>
			✨ Recognised  by Financial1000  and  Wealth&Finance
		</div>,
		<div key={'second'} className='bg-pry rounded-full py-2 w-fit font-light px-4 text-xs sm:text-sm text-[#E7F1FE] transition-[width] duration-500 fade'>
			✨Best Financial access service for the Unbanked
		</div>
	];
	const [textIndex, setTextIndex] = useState(0)
	useEffect(() => {
		const inter = setInterval(() => {
			setTextIndex(e => (e === texts.length - 1) ? 0 : e + 1);
		}, 3000);
		return () => {
			clearInterval(inter)
		}
	}, [texts.length])


	return (

		<div data-aos="fade-up" className='hsection pt-28 md:pt-32'>
			<div className="text-white rounded-t-[1.25rem] sm:rounded-t-[40px] md:bg-[url('assets/young-man-and-lady.png')] bg-light-blue bg-[100%_100%] bg-no-repeat bg-[size:60%] flex px-5 md:px-10 pb-8 sm:pb-16 pt-14 sm:pt-28">
				<div className='max-w-sm'>
					{texts[textIndex]}
					<h3 className='font-libre text-3xl md:text-5xl font-semibold my-5'>Empowering Communities</h3>
					<p className='text-sm sm:text-base'>
						Gate ( formerly AjoBank ) - Introducing the first community banking app that enables users to offer financial services to others.
					</p>
					<AppDownLoad />
				</div>
				{/* <div>
					<div id="getWaitlistContainer" data-waitlist_id="11186" data-widget_type="WIDGET_1"></div>
					<Helmet>
						<link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css" />
						<script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
					</Helmet>
				</div> */}
			</div>
			<div className='bg-pry relative h-16 sm:h-28 px-5 md:px-10 rounded-b-[1.25rem] sm:rounded-b-[40px]'>
				<div className='absolute -top-8 sm:-top-12'><CircularLogo className="h-16 sm:h-28" /></div>
			</div>
		</div>
	)
}

export default DashTop