import PropTypes from 'prop-types';

const LogoWhite = ({className}) => {
	return (
		<svg className={className} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M28.7324 15.9521L23.3183 21.3662L28.7551 26.803" stroke="white" strokeWidth="1.28795" />
			<rect x="30.1406" y="18.2716" width="4.4092" height="4.38539" transform="rotate(45 30.1406 18.2716)" stroke="white" strokeWidth="1.28795" />
			<path d="M26.8594 14.0757L21.4453 19.4898L16.0085 14.053" stroke="white" strokeWidth="1.28795" />
			<rect x="-0.910719" width="4.4092" height="4.38539" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 23.8965 12.0235)" stroke="white" strokeWidth="1.28795" />
			<path d="M14.1094 26.8257L19.5235 21.4116L14.0867 15.9748" stroke="white" strokeWidth="1.28795" />
			<rect x="12.7012" y="24.5063" width="4.4092" height="4.38539" transform="rotate(-135 12.7012 24.5063)" stroke="white" strokeWidth="1.28795" />
			<path d="M16.0176 28.667L21.4317 23.2529L26.8685 28.6897" stroke="white" strokeWidth="1.28795" />
			<rect x="0.910719" width="4.4092" height="4.38539" transform="matrix(0.707107 0.707107 0.707107 -0.707107 17.6925 29.4312)" stroke="white" strokeWidth="1.28795" />
			<path d="M20.837 3.22864C21.0657 2.99987 21.4366 2.99987 21.6654 3.22864L39.2737 20.837C39.5025 21.0657 39.5025 21.4366 39.2737 21.6654L21.6654 39.2737C21.4366 39.5025 21.0657 39.5025 20.837 39.2737L3.22864 21.6654C2.99987 21.4366 2.99987 21.0657 3.22864 20.837L20.837 3.22864Z" stroke="white" strokeWidth="3.98018" />
		</svg>

	)
}


LogoWhite.propTypes = {
	className: PropTypes.string.isRequired
}


export default LogoWhite