import React from 'react'
import AppDownLoad from './AppDownLoad'
import screen1 from '../assets/screen-1.png'
import screen2 from '../assets/screen-2.png'
import screen3 from '../assets/screen-3.png'

const GetGate = () => {
	return (
		<div className='rounded-b-[3.125rem] bg-white relative z-10'>
			<div className="hsection overflow-hidden pb-0 flex flex-col md:flex-row justify-between pt-20 md:pt-48">
				<div className='max-w-sm pb-5' data-aos="fade-right">
					<div className='bg-pry rounded-full py-2 w-fit font-light px-4 text-sm text-[#E7F1FE]'>
						✨Best Financial access service for the Unbanked
					</div>
					<h3 className='font-libre text-4xl md:text-5xl font-semibold my-5'><span className='text-light-blue'>Get</span> Gate</h3>
					<p className='text-neutral-1'>
						The Gate app is available for usage on all mobile phones wherever you are.
					</p>
					<AppDownLoad />
				</div>
				<div className='md:mt-auto mt-12 flex mx-auto sm:mx-0' data-aos="fade-left">
					<img className='rm-drag h-[10rem] sm:h-[14rem]' src={screen3} alt='app-screen-1' />
					<img className='rm-drag h-[14rem] sm:h-[18rem] -mx-20 -mt-[4rem] z-10 ' src={screen1} alt='app-screen-2' />
					<img className='rm-drag h-[10rem] sm:h-[14rem]' src={screen2} alt='app-screen-3' />
				</div>
			</div>
		</div>
	)
}

export default GetGate