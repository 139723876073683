import React, {useRef} from 'react'
import savingsImage from '../assets/savings-image.png';
import savingsImage2 from '../assets/savings-image-2.png';
import savingsImage3 from '../assets/savings-image-3.png';
import savingsImage4 from '../assets/savings-image-4.png';
import RightArr from '../assets/jsx/RightArr'

const MobileViews = () => {
	const scrollContainer = useRef(null)
	return (
		<div className='hsection pt-10 md:pt-16 relative'>
			<div className='max-w-xl'>
				<h3 data-aos="fade-up" data-aos-duration="1000" className='text-2xl tracking-[0.4rem] md:text-5xl font-bold font-libre'>
					Empowering Your Financial <span className='text-light-blue'>Future</span>
				</h3>
				<p className='text-xl my-3 tracking-[0.2rem]'>Innovative Products Tailored for You!</p>
			</div>
			<RightArr onClick={() => scrollContainer.current.scrollLeft -= 200} className="mx-auto hidden xl:block min-w-[2rem] absolute -left-14 bottom-56 cursor-pointer rotate-180" />
			<RightArr onClick={() => scrollContainer.current.scrollLeft += 200} className="mx-auto hidden xl:block min-w-[2rem] absolute -right-14 bottom-56 cursor-pointer" />
			<div className="overflow-hidden">
				<div ref={scrollContainer} data-aos="fade-left" data-aos-delay="500" className='flex scroll-smooth gap-5 overflow-auto no-scrollbar pt-3'>
					<div className='rounded-t-[1.25rem] flex flex-col min-w-[20rem] text-pry hover:text-white bg-form hover:bg-light-blue transition-all duration-300 pt-10 px-5 md:px-7 md:min-w-[25rem]'>
						<h3 className='text-2xl'>Money Transfer</h3>
						<p className='text-sm max-w-xs mb-10 font-normal'>Instant money transfer that enables customers to transfer money to any bank account in Nigeria anytime with reliability and convenience</p>
						<img src={savingsImage} alt='savings' className='w-64 mx-auto rm-drag mt-auto' />
					</div>
					<div className='rounded-t-[1.25rem] flex flex-col min-w-[20rem] bg-[#FDF7E9] hover:bg-tertiary transition-all duration-300 pt-10 px-5 md:px-7 md:min-w-[25rem]'>
						<h3 className='text-2xl'>Credit</h3>
						<p className='text-sm max-w-xs mb-10 font-normal'>Access micro-loans and business loans three easy steps and get credited in twenty-four hours after verification</p>
						<img src={savingsImage2} alt='savings' className='w-64 mx-auto rm-drag mt-auto' />
					</div>
					<div className='rounded-t-[1.25rem] flex flex-col min-w-[20rem] bg-[#E6FCF7] hover:text-[#195042] hover:bg-secondary transition-all duration-300 pt-10 px-5 md:px-7 md:min-w-[25rem]'>
						<h3 className='text-2xl'>Investment</h3>
						<p className='text-sm max-w-xs mb-10 font-normal'>Unleash your investment potential with Gate</p>
						<img src={savingsImage3} alt='savings' className='w-64 mx-auto rm-drag mt-auto' />
					</div>
					<div className='rounded-t-[1.25rem] flex flex-col min-w-[20rem] bg-neutral-background hover:text-white hover:bg-[#2E4EAE] transition-all duration-300 pt-10 px-5 md:px-7 md:min-w-[25rem]'>
						<h3 className='text-2xl'>Insurance</h3>
						<p className='text-sm max-w-xs mb-10 font-normal'>Protect yourself and properties from Loss and damage</p>
						<img src={savingsImage4} alt='Insurance' className='w-64 mx-auto rm-drag mt-auto' />
					</div>
				</div>
			</div>
			<div className='flex xl:hidden pt-4 gap-3 justify-between relative z-40'>
				<RightArr onClick={() => scrollContainer.current.scrollLeft -= 200} className={`stroke-pry stroke-[.3px] cursor-pointer rotate-180`} />
				<RightArr onClick={() => scrollContainer.current.scrollLeft += 200} className="stroke-[.3px] stroke-pry cursor-pointer" />
			</div>
		</div>
	)
}

export default MobileViews