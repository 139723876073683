import {PropTypes} from 'prop-types';

const StarBg = ({className}) => {
	return (
		<svg className={className} width="939" height="488" viewBox="0 0 939 488" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.5">
				<path d="M143.145 46.9368L141.227 45.1222L143.041 43.2043" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 143.421 45.9939)" stroke="white" strokeWidth="0.369636" />
				<path d="M142.383 47.7422L140.465 45.9276L138.65 47.8454" stroke="white" strokeWidth="0.369636" />
				<rect x="141.651" y="48.2462" width="1.5867" height="1.5867" transform="rotate(133.416 141.651 48.2462)" stroke="white" strokeWidth="0.369636" />
				<path d="M137.93 43.5278L139.848 45.3424L138.033 47.2603" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 137.283 44.1011)" stroke="white" strokeWidth="0.369636" />
				<path d="M138.691 42.7227L140.609 44.5373L142.424 42.6194" stroke="white" strokeWidth="0.369636" />
				<rect x="139.423" y="42.2186" width="1.5867" height="1.5867" transform="rotate(-46.5843 139.423 42.2186)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M143.145 46.9368L141.227 45.1222L143.041 43.2043" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 143.421 45.9939)" stroke="white" strokeWidth="0.369636" />
				<path d="M142.383 47.7422L140.465 45.9276L138.65 47.8454" stroke="white" strokeWidth="0.369636" />
				<rect x="141.651" y="48.2462" width="1.5867" height="1.5867" transform="rotate(133.416 141.651 48.2462)" stroke="white" strokeWidth="0.369636" />
				<path d="M137.93 43.5278L139.848 45.3424L138.033 47.2603" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 137.283 44.1011)" stroke="white" strokeWidth="0.369636" />
				<path d="M138.691 42.7227L140.609 44.5373L142.424 42.6194" stroke="white" strokeWidth="0.369636" />
				<rect x="139.423" y="42.2186" width="1.5867" height="1.5867" transform="rotate(-46.5843 139.423 42.2186)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M519.029 156.132L517.111 154.317L518.926 152.4" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 519.306 155.189)" stroke="white" strokeWidth="0.369636" />
				<path d="M518.268 156.938L516.35 155.123L514.535 157.041" stroke="white" strokeWidth="0.369636" />
				<rect x="517.536" y="157.442" width="1.5867" height="1.5867" transform="rotate(133.416 517.536 157.442)" stroke="white" strokeWidth="0.369636" />
				<path d="M513.814 152.723L515.732 154.538L513.918 156.456" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 513.168 153.296)" stroke="white" strokeWidth="0.369636" />
				<path d="M514.576 151.918L516.494 153.733L518.309 151.815" stroke="white" strokeWidth="0.369636" />
				<rect x="515.308" y="151.414" width="1.5867" height="1.5867" transform="rotate(-46.5843 515.308 151.414)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M647.029 25.1321L645.111 23.3175L646.926 21.3996" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 647.306 24.1892)" stroke="white" strokeWidth="0.369636" />
				<path d="M646.268 25.9375L644.35 24.1229L642.535 26.0407" stroke="white" strokeWidth="0.369636" />
				<rect x="645.536" y="26.4416" width="1.5867" height="1.5867" transform="rotate(133.416 645.536 26.4416)" stroke="white" strokeWidth="0.369636" />
				<path d="M641.814 21.7231L643.732 23.5378L641.918 25.4556" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 641.168 22.2964)" stroke="white" strokeWidth="0.369636" />
				<path d="M642.576 20.918L644.494 22.7326L646.309 20.8147" stroke="white" strokeWidth="0.369636" />
				<rect x="643.308" y="20.4139" width="1.5867" height="1.5867" transform="rotate(-46.5843 643.308 20.4139)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M937.029 85.1321L935.111 83.3175L936.926 81.3996" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 937.306 84.1892)" stroke="white" strokeWidth="0.369636" />
				<path d="M936.268 85.9375L934.35 84.1229L932.535 86.0407" stroke="white" strokeWidth="0.369636" />
				<rect x="935.536" y="86.4416" width="1.5867" height="1.5867" transform="rotate(133.416 935.536 86.4416)" stroke="white" strokeWidth="0.369636" />
				<path d="M931.814 81.7231L933.732 83.5378L931.918 85.4556" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 931.168 82.2964)" stroke="white" strokeWidth="0.369636" />
				<path d="M932.576 80.918L934.494 82.7326L936.309 80.8147" stroke="white" strokeWidth="0.369636" />
				<rect x="933.308" y="80.4139" width="1.5867" height="1.5867" transform="rotate(-46.5843 933.308 80.4139)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M400.029 88.1321L398.111 86.3175L399.926 84.3996" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 400.306 87.1892)" stroke="white" strokeWidth="0.369636" />
				<path d="M399.268 88.9375L397.35 87.1229L395.535 89.0407" stroke="white" strokeWidth="0.369636" />
				<rect x="398.536" y="89.4416" width="1.5867" height="1.5867" transform="rotate(133.416 398.536 89.4416)" stroke="white" strokeWidth="0.369636" />
				<path d="M394.814 84.7231L396.732 86.5378L394.918 88.4556" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 394.168 85.2964)" stroke="white" strokeWidth="0.369636" />
				<path d="M395.576 83.918L397.494 85.7326L399.309 83.8147" stroke="white" strokeWidth="0.369636" />
				<rect x="396.308" y="83.4139" width="1.5867" height="1.5867" transform="rotate(-46.5843 396.308 83.4139)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M894.914 265.327L892.996 263.513L894.811 261.595" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 895.191 264.384)" stroke="white" strokeWidth="0.369636" />
				<path d="M894.152 266.133L892.234 264.318L890.42 266.236" stroke="white" strokeWidth="0.369636" />
				<rect x="893.42" y="266.637" width="1.5867" height="1.5867" transform="rotate(133.416 893.42 266.637)" stroke="white" strokeWidth="0.369636" />
				<path d="M889.699 261.918L891.617 263.733L889.802 265.651" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 889.053 262.492)" stroke="white" strokeWidth="0.369636" />
				<path d="M890.461 261.113L892.379 262.928L894.193 261.01" stroke="white" strokeWidth="0.369636" />
				<rect x="891.193" y="260.609" width="1.5867" height="1.5867" transform="rotate(-46.5843 891.193 260.609)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M512.029 364.132L510.111 362.317L511.926 360.4" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 512.306 363.189)" stroke="white" strokeWidth="0.369636" />
				<path d="M511.268 364.938L509.35 363.123L507.535 365.041" stroke="white" strokeWidth="0.369636" />
				<rect x="510.536" y="365.442" width="1.5867" height="1.5867" transform="rotate(133.416 510.536 365.442)" stroke="white" strokeWidth="0.369636" />
				<path d="M506.814 360.723L508.732 362.538L506.918 364.456" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 506.168 361.296)" stroke="white" strokeWidth="0.369636" />
				<path d="M507.576 359.918L509.494 361.733L511.309 359.815" stroke="white" strokeWidth="0.369636" />
				<rect x="508.308" y="359.414" width="1.5867" height="1.5867" transform="rotate(-46.5843 508.308 359.414)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M882.029 400.132L880.111 398.317L881.926 396.4" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 882.306 399.189)" stroke="white" strokeWidth="0.369636" />
				<path d="M881.268 400.938L879.35 399.123L877.535 401.041" stroke="white" strokeWidth="0.369636" />
				<rect x="880.536" y="401.442" width="1.5867" height="1.5867" transform="rotate(133.416 880.536 401.442)" stroke="white" strokeWidth="0.369636" />
				<path d="M876.814 396.723L878.732 398.538L876.918 400.456" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 876.168 397.296)" stroke="white" strokeWidth="0.369636" />
				<path d="M877.576 395.918L879.494 397.733L881.309 395.815" stroke="white" strokeWidth="0.369636" />
				<rect x="878.308" y="395.414" width="1.5867" height="1.5867" transform="rotate(-46.5843 878.308 395.414)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M41.0293 485.132L39.1115 483.317L40.9261 481.4" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 41.306 484.189)" stroke="white" strokeWidth="0.369636" />
				<path d="M40.2676 485.938L38.3497 484.123L36.5351 486.041" stroke="white" strokeWidth="0.369636" />
				<rect x="39.5356" y="486.442" width="1.5867" height="1.5867" transform="rotate(133.416 39.5356 486.442)" stroke="white" strokeWidth="0.369636" />
				<path d="M35.8145 481.723L37.7323 483.538L35.9177 485.456" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 35.1681 482.296)" stroke="white" strokeWidth="0.369636" />
				<path d="M36.5762 480.918L38.494 482.733L40.3086 480.815" stroke="white" strokeWidth="0.369636" />
				<rect x="37.3081" y="480.414" width="1.5867" height="1.5867" transform="rotate(-46.5843 37.3081 480.414)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M7.0293 6.13208L5.11145 4.31747L6.92606 2.39962" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 7.306 5.18916)" stroke="white" strokeWidth="0.369636" />
				<path d="M6.26758 6.9375L4.34973 5.12289L2.53512 7.04073" stroke="white" strokeWidth="0.369636" />
				<rect x="5.5356" y="7.44155" width="1.5867" height="1.5867" transform="rotate(133.416 5.5356 7.44155)" stroke="white" strokeWidth="0.369636" />
				<path d="M1.81445 2.72314L3.7323 4.53776L1.91769 6.4556" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 1.16811 3.29642)" stroke="white" strokeWidth="0.369636" />
				<path d="M2.57617 1.91797L4.49402 3.73258L6.30863 1.81474" stroke="white" strokeWidth="0.369636" />
				<rect x="3.30815" y="1.41392" width="1.5867" height="1.5867" transform="rotate(-46.5843 3.30815 1.41392)" stroke="white" strokeWidth="0.369636" />
			</g>
			<g opacity="0.5">
				<path d="M50.0293 116.132L48.1115 114.317L49.9261 112.4" stroke="white" strokeWidth="0.369636" />
				<rect x="-0.0072263" y="-0.261272" width="1.5867" height="1.5867" transform="matrix(0.687287 -0.726386 -0.726386 -0.687287 50.306 115.189)" stroke="white" strokeWidth="0.369636" />
				<path d="M49.2676 116.938L47.3497 115.123L45.5351 117.041" stroke="white" strokeWidth="0.369636" />
				<rect x="48.5356" y="117.442" width="1.5867" height="1.5867" transform="rotate(133.416 48.5356 117.442)" stroke="white" strokeWidth="0.369636" />
				<path d="M44.8145 112.723L46.7323 114.538L44.9177 116.456" stroke="white" strokeWidth="0.369636" />
				<rect x="0.0072263" y="0.261272" width="1.5867" height="1.5867" transform="matrix(-0.687287 0.726386 0.726386 0.687287 44.1681 113.296)" stroke="white" strokeWidth="0.369636" />
				<path d="M45.5762 111.918L47.494 113.733L49.3086 111.815" stroke="white" strokeWidth="0.369636" />
				<rect x="46.3081" y="111.414" width="1.5867" height="1.5867" transform="rotate(-46.5843 46.3081 111.414)" stroke="white" strokeWidth="0.369636" />
			</g>
		</svg>


	)
}
StarBg.propTypes = {
	className: PropTypes.string.isRequired
}
export default StarBg