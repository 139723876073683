import React from 'react'
import LogoWhite from '../assets/jsx/LogoWhite'
import Twitter from '../assets/jsx/Twitter';
import Facebook from '../assets/jsx/Facebook';
import Insta from '../assets/jsx/Insta';
import AppDownLoad from './AppDownLoad';

const Footer = () => {
	return (
		<div className='bg-pry pb-10 pt-32 -mt-10 relative '>
			<div data-aos="fade-up" className='hsection text-white relative z-[100]'>
				<div className='flex flex-wrap md:justify-between gap-10 '>
					<div>
						<span className='flex items-center text-white gap-2'>
							<LogoWhite className="w-10" />
							<span className='font-bold text-2xl'>Gate</span>
						</span>
						<div className='text-md py-3 max-w-[14rem] font-light'> Join over +5000 people using Gate ( formerly AjoBank ) in their community and earning	money daily</div>
						{/* <div className='flex gap-5 md:gap-10 mt-10 items-center'>
							<Insta />
							<Twitter />
							<Facebook />
						</div> */}
					</div>
					<div className='flex w-full md:w-fit flex-wrap gap-10 justify-between md:gap-16 lg:gap-24'>
						<div>
							<h2 className='font-bold text-lg mb-2'>Company</h2>
							<ul className='class font-light'>
								<li className='cursor-pointer'>About Us</li>
								<li className='cursor-pointer'>Our Teams</li>
								<li className='cursor-pointer'>Our Partners</li>
							</ul>
							{/* <span className='block mt-16 font-bold text-sm'>For Partnership</span>
							<span className='text-sm text-light text-secondary'>partners@gate.com.ng</span> */}
						</div>
						<div>
							<h2 className='font-bold text-lg mb-2'>Resources</h2>
							<ul className='class font-light'>
								<li className='cursor-pointer'>FAQs</li>
								<li className='cursor-pointer'>Blog</li>
							</ul>
							{/* <span className='block mt-16 font-bold text-sm'>Contact Info</span>
							<span className='text-sm text-light text-secondary'>Silverbird Boulevard, Lagos
								Nigeria</span> */}
						</div>
						<div>
							<h2 className='font-bold text-lg mb-2'>Legal</h2>
							<ul className='class font-light'>
								<li className='cursor-pointer'>Privacy</li>
								<li className='cursor-pointer'>Security</li>
								<li className='cursor-pointer'>Compliance</li>
								<li className='cursor-pointer'>Terms</li>
							</ul>
							{/* <span className='block mt-16 font-bold text-sm'>support@gate.ng</span>
							<span className='text-sm text-light text-secondary'>+234987654678</span> */}
						</div>
						<AppDownLoad className="pt-0 sm:flex-col" />
					</div>
				</div>
				<div className='flex flex-wrap md:justify-between gap-5 md:gap-10'>
					<div className='flex gap-5 md:min-w-[12rem] w-full md:w-fit md:gap-10 mt-5 items-center'>
						<Insta />
						<Twitter />
						<Facebook />
					</div>
					<div>
						<span className='block mt-5 font-bold text-sm'>For Partnership</span>
						<span className='text-sm text-light text-secondary'>support@getgate.africa</span>
					</div>
					<div>
						<span className='block mt-5 font-bold text-sm'>Contact Info</span>
						<span className='text-sm text-light text-secondary'>Silverbird Boulevard, Lagos
							Nigeria</span>
					</div>
					<div>
						<span className='block mt-5 font-bold text-sm'>support@getgate.africa</span>
						<span className='text-sm text-light text-secondary'>+234987654678</span>
					</div>
				</div>
				<div className='text-xs pt-16'>Community  Banking Services backed by Wema Bank and regulated by the Central Bank of Nigeria</div>
			</div>
		</div>
	)
}

export default Footer