import React, { useEffect, useState } from 'react'
import Partners from '../components/Partners';
import MobileViews from '../components/MobileViews';
import Services from '../components/Services';
import Cardbox from '../components/Cardbox';
import Testimonial from '../components/Testimonial';
import FAQs from '../components/FAQs';
import GetGate from '../components/GetGate';
import Footer from '../components/Footer';
import DashTop from '../components/DashTop';
import Navbar from '../components/Navbar';

const LandingPage = () => {
	const [scrolled, setScrolled] = useState(false)
	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent)
		return () => {
			window.removeEventListener('scroll', listenScrollEvent)
		}
	}, [])
	const listenScrollEvent = () => {
		if (window.scrollY > 50) {
			setScrolled(true)
		} else {
			setScrolled(false)
		}
	}
	const handleDownload = () => {
		// Replace this URL with the actual URL of your APK file
		const apkUrl = '/gate-app/gate.apk';
		
		// Create a temporary anchor element
		const link = document.createElement('a');
		link.href = apkUrl;
		link.setAttribute('download', 'gate.apk');
		
		// Append to the body, click programmatically, and remove
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<div className='relative'>
			<Navbar />
			<DashTop />
			<Partners />
			<MobileViews />
			<Services />
			<Cardbox />
			<Testimonial />
			<FAQs />
			<GetGate />
			<Footer />
			{/* Bottom Sheet */}
			<div
				className={`fixed inset-x-0 bottom-0 transform ${
					scrolled ? 'translate-y-0' : 'translate-y-full'
				} transition-transform duration-300 ease-in-out`}
			>
				<div className="bg-light-blue p-4 rounded-t-lg shadow-lg">
					<div className="flex justify-between items-center">
						<p className="text-md-lg text-sm font-semibold text-white">Can&apos;t wait to use Gate?</p>
						<button
							onClick={handleDownload}
							className="bg-white text-blue-500 px-4 py-2 rounded"
						>
							Download APK Now
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage