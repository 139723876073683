import PropTypes from 'prop-types';

const DropDownArr = ({className}) => {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.58268 14.1665L8.4756 12.2566C9.98516 10.7335 10.7399 9.97198 11.6599 9.85472C11.8853 9.82599 12.1134 9.82599 12.3388 9.85472C13.2588 9.97198 14.0135 10.7335 15.5231 12.2566L17.416 14.1665M22.8327 11.9998C22.8327 17.9829 17.9824 22.8332 11.9993 22.8332C6.01626 22.8332 1.16602 17.9829 1.16602 11.9998C1.16602 6.01675 6.01626 1.1665 11.9993 1.1665C17.9824 1.1665 22.8327 6.01675 22.8327 11.9998Z" className='stroke-inherit' strokeWidth="1.5" strokeLinecap="round" />
		</svg>

	)
}

DropDownArr.propTypes = {
	className: PropTypes.string.isRequired
}

export default DropDownArr